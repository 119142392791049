import React from "react"
import { Helmet } from "react-helmet"
import Faq from "../components/Home/FAQ/Faq"
import Footer from "../components/Home/Footer/Footer"
import Header from "../components/Home/Header/Header"
import Technology from "../components/Home/Technology/Technology"
import Features from "../components/Home/Features/Features"
import "../styles/global.css"
import Services from "../components/Home/Services/Services"
import TechnologyStack from "../components/Home/TechnologyStack/TechnologyStack"
import Timeline from "../components/Home/Timeline/Timeline"
import logo from "../images/sensetechLogo.png"

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SenseTech: Home</title>
        <meta
          name="description"
          content="SenseTech : App Development || Cloud Support || Migration to Micro Frontend || Cloud Migration support || RPA || Chatbot || E-commerce store"
        />
        <meta
          name="keywords"
          content="mobile app development , web app development , software company , IT solution, IT services"
        />
        <meta name="author" content="SenseTech Innovations Private Limited" />
        <meta property="og:image" content={logo} />
        <link rel="canonical" href="https://thesensetech.com" />
      </Helmet>
      <Header />
      <Services />
      <Technology />
      <TechnologyStack />
      <Timeline />
      <Features />
      <Faq />
      <Footer />
    </>
  )
}
